
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import Head from 'next/head';
import NextNProgress from 'nextjs-progressbar';
import 'react-tooltip/dist/react-tooltip.css';
import colors from 'tailwindcss/colors';
import { initDatadog } from '@elendi/util-tracking';
import { serialKill } from '@elendi/feature-superjson-serial-killer';
import './styles.css';
if (typeof window !== 'undefined') {
    const datadogClientToken = process.env['NEXT_PUBLIC_DATADOG_CLIENT_TOKEN'];
    if (datadogClientToken)
        initDatadog(datadogClientToken);
}
const App = <T extends {
    session: Session;
}>({ Component, pageProps }: AppProps<T>) => {
    const parsedPageProps = serialKill(pageProps);
    return (<SessionProvider session={pageProps.session}>
      <Head>
        {/*
          Used to make Typeform pop-up take the right size on mobile screens
          https://community.typeform.com/integrate-your-typeform-43/help-bug-number-element-on-android-super-zoom-4708
        */}
        <meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1, interactive-widget=resizes-content'/>
      </Head>

      <NextNProgress color={colors.slate[600]} height={4} showOnShallow={false} options={{ showSpinner: false }}/>

      <ThemeProvider attribute='class'>
        <Component {...parsedPageProps}/>
      </ThemeProvider>
    </SessionProvider>);
};
const __Next_Translate__Page__19498d7614d__ = App;

    export default __appWithI18n(__Next_Translate__Page__19498d7614d__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  